body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.richtext-container {
  white-space: normal;
}

.richtext-container p {
  padding: 0;
  margin: 0 0 14px 0;
}

.richtext-container ol, .richtext-container ul {
  margin: 16px 0;
}

.richtext-container blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: Hoefler Text,Georgia,serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}